import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20250131-Rate-Sheet.pdf";
const date = "31.01.2025";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>
      <strong>Essential reading article template</strong>
    </p>
    <p>Friday 31 January 2025, HSBC UK</p>
    <p>
      <strong>Changes to our mortgage products</strong>
    </p>
    <p>With effect from Friday 31 January 2025 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>All of our fixed rate end dates will be changed as below:</p>
    <ul>
      <li>2 Year Fixed &ndash; 28 February 2027 changed to 31 May 2027</li>
      <li>3 Year Fixed &ndash; 28 February 2028 changed to 31 May 2028</li>
      <li>5 Year Fixed &ndash; 28 February 2030 changed to 31 May 2030</li>
      <li>10 Year Fixed &ndash; 28 February 2035 changed to 31 May 2035</li>
    </ul>
    
    <p>An updated mortgage rate sheet will be published on Friday 31 January 2025 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
